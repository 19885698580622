#laeditor{
	padding: 0px;
	.la-header {
		text-align: center;
		padding: 5px;
		background: #444;
		color: #FFF;
		border-bottom: solid 1px #333;
		border-right: solid 1px #333;
		
		.la-name {
			
		}
		.la-dir {
			
		}
	}
	margin: 0px;
	.col-md-2, .col-md-10{padding:0px;}
	.la-file-tree {
		overflow-y: scroll;
    	overflow-x: hidden;
		padding: 5px;
		background: #222;
		border-right: solid 1px #333;
		
		a {
			display: block;
			width: 100%;
			color: darkgrey;
		}
		a:hover {
			background: rgba(255, 255, 255, 0.29);
		}
	}
	.laeditor-tabs {
		list-style: none;
		margin: 0px;
    	padding: 0px;
		background: #232323;
		display: block;
		float: left;
		width: 100%;
		border-bottom: solid 1px;
		height: 31px;
		
		li {
			display: inline-block;
    		padding: 4px 10px;
			padding-right: 8px;
			padding-bottom: 6px;
			color: beige;
			background: #222;
			border-right: solid 1px #333;
			margin: 0px;
    		float: left;
			cursor: pointer;
			
			i.fa {
				font-size: 10px;
				margin-left: 3px;
				color: #555;
			}
		}
		li.active {
			background: #444;
			
			i.fa {
				color: #999;
			}
		}
		li:hover {
			background: #333;
			
			i.fa {
				color: #999;
			}
		}
	}
	#la-ace-editor {
		display: block;
		border-radius: 0px;
		border: 0px;
		margin: 0px;
		
		.ace_gutter{
			margin-top: 0px;
		}
		.ace_scroller {
			margin-top: 0px;
		}
	}
}