/*
 * Component: Nav
 * --------------
 */

.nav {
  > li > a:hover,
  > li > a:active,
  > li > a:focus {
    color: #444;
    background: #f7f7f7;
  }
}

/* NAV PILLS */
.nav-pills {
  > li > a {
    .border-radius(0);
    border-top: 3px solid transparent;
    color: #444;
    > .fa,
    > .glyphicon,
    > .ion {
      margin-right: 5px;
    }
  }
  > li.active > a,
  > li.active > a:hover,
  > li.active > a:focus {
    border-top-color: @light-blue;
  }
  > li.active > a {
    font-weight: 600;
  }
}

/* NAV STACKED */
.nav-stacked {
  > li > a {
    .border-radius(0);
    border-top: 0;
    border-left: 3px solid transparent;
    color: #444;
  }
  > li.active > a,
  > li.active > a:hover {
    background: transparent;
    color: #444;
    border-top: 0;
    border-left-color: @light-blue;
  }

  > li.header {
    border-bottom: 1px solid #ddd;
    color: #777;
    margin-bottom: 10px;
    padding: 5px 10px;
    text-transform: uppercase;
  }
}

/* NAV TABS */
.nav-tabs-custom {
  margin-bottom: 20px;
  background: #fff;
  box-shadow: @box-boxshadow;
  border-radius: @box-border-radius;
  > .nav-tabs {
    margin: 0;
    border-bottom-color: #f4f4f4;
    .border-top-radius(@box-border-radius);
    > li {
      border-top: 3px solid transparent;
      margin-bottom: -2px;
      > a {
        color: #444;
        .border-radius(0);
        &.text-muted {
          color: #999;
        }
        &,
        &:hover {
          background: transparent;
          margin: 0;
        }
        &:hover {
          color: #999;
        }
      }
      &:not(.active) {
        > a:hover,
        > a:focus,
        > a:active {
          border-color: transparent;
        }
      }
      margin-right: 5px;
    }

    > li.active {
      border-top-color: @light-blue;
      & > a,
      &:hover > a {
        background-color: #fff;
        color: #444;
      }
      > a {
        border-top-color: transparent;
        border-left-color: #f4f4f4;
        border-right-color: #f4f4f4;
      }

    }

    > li:first-of-type {
      margin-left: 0;
      &.active {
        > a {
          border-left-color: transparent;
        }
      }
    }

    //Pulled to the right
    &.pull-right {
      float: none !important;
      > li {
        float: right;
      }
      > li:first-of-type {
        margin-right: 0;
        > a {
          border-left-width: 1px;
        }
        &.active {
          > a {
            border-left-color: #f4f4f4;
            border-right-color: transparent;
          }
        }
      }
    }

    > li.header {
      line-height: 35px;
      padding: 0 10px;
      font-size: 20px;
      color: #444;
      > .fa,
      > .glyphicon,
      > .ion {
        margin-right: 5px;
      }
    }
  }

  > .tab-content {
    background: #fff;
    padding: 10px;
    .border-bottom-radius(@box-border-radius);
  }

  .dropdown.open > a {
    &:active,
    &:focus {
      background: transparent;
      color: #999;
    }
  }
  // Tab color variations
  &.tab-primary {
    > .nav-tabs {
      > li.active {
        border-top-color: @light-blue;
      }
    }
  }
  &.tab-info {
    > .nav-tabs {
      > li.active {
        border-top-color: @aqua;
      }
    }
  }
  &.tab-danger {
    > .nav-tabs {
      > li.active {
        border-top-color: @red;
      }
    }
  }
  &.tab-warning {
    > .nav-tabs {
      > li.active {
        border-top-color: @yellow;
      }
    }
  }
  &.tab-success {
    > .nav-tabs {
      > li.active {
        border-top-color: @green;
      }
    }
  }
  &.tab-default {
    > .nav-tabs {
      > li.active {
        border-top-color: @gray;
      }
    }
  }
}

/* PAGINATION */
.pagination {
  > li > a {
    background: #fafafa;
    color: #666;
  }
  &.pagination-flat {
    > li > a {
      .border-radius(0) !important;
    }
  }
}

/*tab*/

.nav-tabs.profile{
    background: #f2f4f6; 
    border-bottom: 1px solid #f2f2f2;
}
.nav-tabs.profile>li>a{
    padding: 15px;
    margin-right: 0;
    border-radius: 0;
    color: inherit;
}
.nav-tabs.profile>li>a:hover{
    background: transparent;
    border-color: transparent;
    color: @blue;
}
.nav-tabs.profile>li.active>a,
.nav-tabs.profile>li.active>a:active,
.nav-tabs.profile>li.active>a:hover,
.nav-tabs.profile>li.active>a:focus{
    border:none;
    color: @blue;
    background: transparent;
    border-bottom:2px solid @blue;
    border-radius: 0;
}
.nav-tabs.profile>li>a i.fa{
    width: 14px;
    text-align: center;
    margin-right: 4px;
}
.nav-tabs.profile.classic{
    margin-bottom: 15px;
}
.nav-tabs.profile.vertical{
    background-color: transparent;
}
.nav-tabs.profile.vertical li{
    float: none;
}
.nav-tabs.profile>li:not(.title-tab):after {
    display: block;
    background-color: @blue;
    content: "";
    left: 0;
    bottom: 0px;
    width: 0;
    height: 2px;
    position: absolute;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
.nav-tabs.profile>li:not(.active):hover:after {
    width: 100%;
}

.tab-title {
    border-bottom: 1px solid #e5e9ec;
}
.tab-title h4 {
    margin: 2px 0 0 0;
    padding: 15px;
    float: left;
}
.tab-title .btn {
    margin: 7px 5px;
}
.tab-title .title-button-group {
    float: right;
    margin: 0 10px 0;

}