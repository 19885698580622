.pgn-wrapper{position:fixed;z-index:1000;}
.pgn-wrapper[data-position$='-left']{left:30px;}
.pgn-wrapper[data-position$='-right']{right:20px;}
.pgn-wrapper[data-position^='top-']{top:20px;}
.pgn-wrapper[data-position^='bottom-']{bottom:20px;}
.pgn-wrapper[data-position='top']{top:0;left:0;right:0;}
.pgn-wrapper[data-position='bottom']{bottom:0;left:0;right:0;}
.pgn{position:relative;margin:10px;}
.pgn .alert{margin:0;}
.pgn-simple .alert{padding-top:13px;padding-bottom:13px;max-width:500px;animation:fadeIn 0.3s cubic-bezier(0.05,0.74,0.27,0.99) forwards;-webkit-animation:fadeIn 0.3s cubic-bezier(0.05,0.74,0.27,0.99) forwards;max-height:250px;overflow:hidden;}
.pgn-bar{overflow:hidden;margin:0;}
.pgn-bar .alert{border-radius:0;padding-top:13px;padding-bottom:13px;max-height:91px;}
.pgn-wrapper[data-position='top'] .pgn-bar .alert{animation:slideInFromTop 0.5s cubic-bezier(0.05,0.74,0.27,0.99) forwards;-webkit-animation:slideInFromTop 0.5s cubic-bezier(0.05,0.74,0.27,0.99) forwards;transform-origin:top left;-webkit-transform-origin:top left;}
.pgn-wrapper[data-position='bottom'] .pgn-bar .alert{animation:slideInFromBottom 0.5s cubic-bezier(0.05,0.74,0.27,0.99) forwards;-webkit-animation:slideInFromBottom 0.5s cubic-bezier(0.05,0.74,0.27,0.99) forwards;transform-origin:bottom left;-webkit-transform-origin:bottom left;}
.pgn-bar .alert span{opacity:0;animation:fadeIn 0.3s cubic-bezier(0.05,0.74,0.27,0.99) forwards;-webkit-animation:fadeIn 0.3s cubic-bezier(0.05,0.74,0.27,0.99) forwards;}
@keyframes slideInFromTop{
	0%{transform:translateY(-100%);}
100%{transform:translateY(0);}
}
@-webkit-keyframes slideInFromTop{0%{-webkit-transform:translateY(-100%);}
100%{-webkit-transform:translateY(0);}
}
@keyframes slideInFromBottom{0%{transform:translateY(100%);}
100%{transform:translateY(0);}
}
@-webkit-keyframes slideInFromBottom{0%{-webkit-transform:translateY(100%);}
100%{-webkit-transform:translateY(0);}
}
.pgn-circle .alert{border-radius:30px;animation:fadeInCircle 0.3s ease forwards,resizeCircle 0.3s 0.4s cubic-bezier(0.25,0.25,0.4,1.6) forwards;-webkit-animation:fadeInCircle 0.3s ease forwards,resizeCircle 0.3s 0.4s cubic-bezier(0.25,0.25,0.4,1.6) forwards;min-height:60px;height:auto;overflow:hidden;padding:6px 55px 6px 6px;-webkit-transform:translateZ(0);position:relative;}
.pgn-wrapper[data-position$='-right'] .pgn-circle .alert{float:right;}
.pgn-wrapper[data-position$='-left'] .pgn-circle .alert{float:left;}
.pgn-circle .alert>div>div.pgn-thumbnail>div{border-radius:50%;overflow:hidden;width:48px;height:48px;}
.pgn-circle .alert>div>div.pgn-thumbnail>div>img{width:100%;height:100%;}
.pgn-circle .alert>div>div.pgn-message>div{opacity:0;min-height:47px;height:auto;padding-left:9px;animation:fadeIn .3s .5s ease forwards;-webkit-animation:fadeIn .3s .5s ease forwards;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;word-break:break-all;word-wrap:break-word;}
.pgn-circle .alert>div>div.pgn-message>div p:only-child{padding:12px 0;}
.pgn-circle .alert .close{margin-top:-12px;position:absolute;right:18px;top:50%;opacity:0;animation:fadeIn .3s .5s ease forwards;-webkit-animation:fadeIn .3s .5s ease forwards;color:#FFF;}
.pgn-circle .alert p{margin-bottom:0;width:200px;height:auto;white-space:pre-wrap;}
.pgn-circle .alert>div{display:table;height:100%;}
.pgn-circle .alert>div>div{display:table-cell;vertical-align:top;}
@keyframes fadeInCircle{0%{opacity:0;width:60px;}
100%{opacity:1;width:60px;}
}
@-webkit-keyframes fadeInCircle{0%{opacity:0;width:60px;}
100%{opacity:1;width:60px;}
}
@keyframes resizeCircle{0%{width:60px;}
100%{width:300px;}
}
@-webkit-keyframes resizeCircle{0%{width:60px;}
100%{width:300px;}
}
.pgn-wrapper[data-position^='top-'] .pgn-flip{top:-30px;}
.pgn-wrapper[data-position^='bottom-'] .pgn-flip{bottom:-30px;}
.pgn-wrapper[data-position^='bottom-'] .pgn-flip .alert{-webkit-transform-origin:50% 100%;transform-origin:50% 100%;}
.pgn-flip .alert{-webkit-transform-origin:50% 0%;transform-origin:50% 0%;box-shadow:0 6px 4px -3px rgba(0,0,0,0.2);-webkit-animation-name:flipInX;animation-name:flipInX;-webkit-animation-duration:0.8s;animation-duration:0.8s;border-radius:0;padding:25px 35px;max-width:500px;max-height:250px;overflow:hidden;}
@-webkit-keyframes flipInX{0%{-webkit-transform:perspective(400px) rotate3d(1,0,0,-90deg);-webkit-transition-timing-function:ease-in;}
40%{-webkit-transform:perspective(400px) rotate3d(1,0,0,20deg);-webkit-transition-timing-function:ease-out;}
60%{-webkit-transform:perspective(400px) rotate3d(1,0,0,-10deg);-webkit-transition-timing-function:ease-in;opacity:1;}
80%{-webkit-transform:perspective(400px) rotate3d(1,0,0,5deg);-webkit-transition-timing-function:ease-out;}
100%{-webkit-transform:perspective(400px);}
}
@keyframes flipInX{0%{-webkit-transform:perspective(400px) rotate3d(1,0,0,-90deg);transform:perspective(400px) rotate3d(1,0,0,-90deg);-webkit-transition-timing-function:ease-in;transition-timing-function:ease-in;}
40%{-webkit-transform:perspective(400px) rotate3d(1,0,0,20deg);transform:perspective(400px) rotate3d(1,0,0,20deg);-webkit-transition-timing-function:ease-out;transition-timing-function:ease-out;}
60%{-webkit-transform:perspective(400px) rotate3d(1,0,0,-10deg);transform:perspective(400px) rotate3d(1,0,0,-10deg);-webkit-transition-timing-function:ease-in;transition-timing-function:ease-in;opacity:1;}
80%{-webkit-transform:perspective(400px) rotate3d(1,0,0,5deg);transform:perspective(400px) rotate3d(1,0,0,5deg);-webkit-transition-timing-function:ease-out;transition-timing-function:ease-out;}
100%{-webkit-transform:perspective(400px);transform:perspective(400px);}
}
@keyframes fadeIn{0%{opacity:0;}
100%{opacity:1;}
}
@-webkit-keyframes fadeIn{0%{opacity:0;}
100%{opacity:1;}
}
body>.pgn-wrapper[data-position="top"]{top:60px;left:70px;}
body>.pgn-wrapper[data-position="bottom"]{left:70px;}
body>.pgn-wrapper[data-position$='-left']{left:90px;right:auto;}
body>.pgn-wrapper[data-position^='top-']{top:80px;bottom:auto;}
body>.pgn-wrapper{z-index:900;}
@media (max-width:979px){body>.pgn-wrapper[data-position="top"]{left:0;}
body>.pgn-wrapper[data-position="bottom"]{left:0;}
}
@media (max-width:767px){body>.pgn-wrapper[data-position$='-left'],body>.pgn-wrapper[data-position$='-right']{left:10px!important;right:10px!important;}
body>.pgn-wrapper[data-position$='-right'] .alert,body>.pgn-wrapper[data-position$='-left'] .alert{max-width:100%;width:100%;}
}