/* =================== Uploads =================== */

#fm_dropzone_main{
  border: 1px dashed #0087F7;
  border-radius: 5px;
  background: white;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  /*min-height: 100px;*/
  padding: 8px;
  vertical-align: middle;
  cursor: pointer;
  div.dz-message {
    display: block;
    padding: 20px 0px;
    color: #999;
    i.fa {
      font-size: 40px;
    }
  }
  #closeDZ1{
    display: block;
    position: relative;
    width: 10px;
    float: right;
    margin-top: -2px;
    margin-right: 2px;
  }
}

@preview_size: 130px;
ul.files_container {
  list-style: none;
  padding: 10px;
  margin: 0px;
  display: inline-block;
  height: 100%;
  width: 100%;
  min-height: 300px;
  overflow: scroll;
  li {
    display: inline-block;
    max-width: @preview_size;
    height: @preview_size;
    width: @preview_size;
    float: left;
    margin-bottom: 5px;
    margin-right: 5px;
    overflow: hidden;
    border: solid 1px #EEE;
    a {
      display: block;
      height: 100%;
      cursor: pointer;
      img {
        height: @preview_size - 2;
        display: block;
        transform: translateX(-50%);
        margin-left: 50%;
      }
      i.fa{
        height: @preview_size - 2;
        width: @preview_size - 2;
        font-size: 96px;
        text-align: center;
        padding-top: 13px;
      }
    }
  }
  li:hover {
    border-color: #48B0F7;
  }
}
#EditFileModal {
  .modal-header {
    padding: 0px;
    border-bottom-color: #ddd;
    h4.modal-title {
      padding: 13px 15px;
    }
    button {
      float: right;
      padding: 15px;
      background: #FFF;
      border: 0px;
      border-left: solid 1px #ddd;
      text-align: center;
      width: 50px;
      height: 51px;
      margin-top: 0px;
      font-size: 17px;
      color: #d6d6d6;
      opacity: 1;
    }
    button:hover {
      color: #bbb;
      background: #EEE;
    }
  }
  .modal-footer {
    border-top-color: #ddd;
  }
  .file-info-form {
    padding: 10px;
    height: 330px;
    background: #f3f3f3;
    border-left: solid 1px #ddd;
    label {
      color: #666;
      font-weight: normal;
    }
  }
  .col-xs-8, .col-xs-4 {
    padding: 0px;
  }
  .fileObject {
    text-align: center;
    padding: 15px 0px;
    img {
      max-width: 100%;
      max-height: 300px;
    }
  }
}
/* =================== File Browser + Uploader =================== */

.input-group.file .input-group-addon {
  cursor: pointer;
}
.input-group-addon.preview {
  display: none;
  cursor: pointer;
  padding: 0px;
  width: 34px;
  height: 34px;
  border-left: 0px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  right: 40px;
  z-index: 10;
}
#fm{
  .col-md-3{
    border-right: solid 1px #F4F4F4;
  }
  .fm_folder_selector {
    padding: 15px 0px;
    min-height: 341px;
    
    .fm_folder_title {
      display: block;
      padding: 4px 15px;
      color: #777;
      font-weight: 100;
    }
    .fm_folder_sel {
      display: block;
      padding: 4px 15px;
      color: #666;
      max-width: 120px;
      cursor: pointer;
    }
    .fm_folder_sel:hover {
      color: #48B0F7;
    }
    .fm_folder_sel.selected {
      border: solid 1px #EEE;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      background: #EEE;
    }
    
    form#fm_dropzone{
      border: 1px dashed #0087F7;
      border-radius: 5px;
      background: white;
      text-align: center;
      margin-left: 15px;
      margin-top: 15px;
      min-height: 200px;
      padding: 8px;
      vertical-align: middle;
      cursor: pointer;
      div.dz-message {
        display: block;
        padding: 51px 0px;
        color: #999;
        i.fa {
          font-size: 40px;
        }
      }
    }
  }
  .nav{
    background: #EEE;
    input[type=search]{
      padding: 5px;
      line-height: 12px;
      height: 28px;
      margin: 4px 5px;
      border-color: #EEE;
      border-radius: 3px;
      font-weight: 400;
    }
  }
  .fm_file_selector {
    ul {
      list-style: none;
      padding: 10px;
      margin: 0px;
      display: inline-block;
      height: 100%;
      width: 100%;
      min-height: 300px;
      overflow: scroll;
      li {
        display: inline-block;
        max-width: 104px;
        max-height: 104px;
        min-height: 104px;
        float: left;
        margin-bottom: 5px;
        margin-right: 5px;
        overflow: hidden;
        border: solid 1px #EEE;
        a {
          display: block;
          height: 100%;
          cursor: pointer;
          img {
            height: 102px;
            display: block;
            transform: translateX(-50%);
            margin-left: 50%;
          }
          i.fa{
            height: 102px;
            width: 102px;
            font-size: 96px;
            text-align: center;
            padding-top: 3px;
          }
        }
      }
      li:hover {
        border-color: #48B0F7;
      }
    }
  }
}

/* =================== Upload in Form =================== */
a.btn_upload_file, a.btn_upload_image {
  margin-top: 10px;
  border-radius: 3px;
  border: solid 1px #ccc;
  background: #FFF;

  i.fa {
    margin-left: 3px;
  }
}
a.btn_upload_file:hover, a.btn_upload_image:hover {
  background: #EEE;
}
div.uploaded_image {
  display: inline-block;
  position: relative;
  margin-top: 7px;

  img {
    max-width: 120px;
    border-radius: 3px;
  }
  i.fa {
    background: rgba(0, 0, 0, 0.65);
    display: block;
    position: absolute;
    top: -6px;
    right: -6px;
    color: #FFF;
    padding: 2px 2px;
    border-radius: 50%;
    text-align: center;
    width: 15px;
    height: 15px;
    font-size: 11px;
    cursor: pointer;
  }
}

a.uploaded_file {
  display: inline-block;
  position: relative;
  margin-top: 7px;
  vertical-align: top;
  margin-right: 10px;

  i.fa.fa-file-o {
    font-size: 90px;
  }
  i.fa.fa-times {
    background: #f10000;
    display: block;
    position: absolute;
    top: -6px;
    right: -6px;
    color: #FFF;
    padding: 2px 2px;
    border-radius: 50%;
    text-align: center;
    width: 15px;
    height: 15px;
    font-size: 11px;
    cursor: pointer;
  }
}

a.uploaded_file2 {
  display: inline-block;
  position: relative;
  margin-top: 7px;
  vertical-align: top;
  margin-right: 10px;

  i.fa.fa-file-o {
    font-size: 90px;
  }
  i.fa.fa-times {
    background: #f10000;
    display: block;
    position: absolute;
    top: -6px;
    right: -6px;
    color: #FFF;
    padding: 2px 2px;
    border-radius: 50%;
    text-align: center;
    width: 15px;
    height: 15px;
    font-size: 11px;
    cursor: pointer;
  }
}